import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useMemo, useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';
// @mui
import {
  Grid,
  Stack,
  Dialog,
  Button,
  Divider,
  MenuItem,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
} from '@mui/material';

// components

import { useTheme } from '@emotion/react';

import { LoadingButton } from '@mui/lab';

import { useResponsive } from 'src/hooks/use-responsive';

import OspImageUploader from 'src/pages/Common/upload';
import { createBanner, updateBannerDetails } from 'src/server/api/banner';

import FormProvider, { RHFSelect, RHFTextField, RHFRadioGroup } from 'src/components/hook-form';

import VideoPreview from '../../Products/sections/VideoPreview';

CreateBannerDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setOpenBannerId: PropTypes.func,
  isUpdate: PropTypes.object,
  moduleId: PropTypes.any,
  moduleName: PropTypes.any,
  filterDataObj: PropTypes.any,
};

export default function CreateBannerDialog({
  open,
  setOpen,
  isUpdate,
  moduleId,
  moduleName,
  setOpenBannerId,
  filterDataObj,
}) {
  const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  // const isMobile = useResponsive('sm', 'xs');
  const [videoPreviewPopup, setVideoPreviewPopup] = useState({
    data: {},
    status: false,
  });

  // console.log(isUpdate);
  const handleClose = () => {
    setOpen(false);
    setOpenBannerId({});
  };
  const dispatch = useDispatch();
  // const router = useRouter();

  const loading = useSelector((state) => state.bannerData.loading);
  const isMobile = useResponsive('sm', 'xs');
  const [imagePreview, setImagePreview] = useState([]);

  const FormSchema = Yup.object().shape({
    video_id: Yup.string(),
    banner_type: Yup.string(),
    platform: Yup.string(),
    position: Yup.string(),
    link_type: Yup.string(),
    link: Yup.string(),
  });

  const defaultValues = useMemo(() => {
    if (isUpdate?.uid) {
      return {
        video_id: isUpdate?.video_id,
        banner_type: isUpdate?.banner_type || 'IMAGE',
        platform: isUpdate?.platform || 'COMMON',
        priority: isUpdate?.priority || 0,
        position: isUpdate?.position || 'MAIN',
        link_type: isUpdate?.link_type || 'INTERNAL',
        link: isUpdate?.link,
      };
    }
    return {
      video_id: '',
      banner_type: 'IMAGE',
      platform: 'COMMON',
      priority: 0,
      position: 'MAIN',
      link_type: 'INTERNAL',
      link: '',
    };
  }, [
    isUpdate?.uid,
    isUpdate?.video_id,
    isUpdate?.banner_type,
    isUpdate?.platform,
    isUpdate?.priority,
    isUpdate?.position,
    isUpdate?.link_type,
    isUpdate?.link,
  ]);

  const methods = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const bannerType = watch('banner_type');

  const handleClear = () => {
    reset(defaultValues);
  };
  const onSubmit = handleSubmit(async (data) => {
    const baseState = {
      ...data,
      module: moduleId ? moduleName : 'HOME',
      image: imagePreview?.length > 0 ? imagePreview[0] : {},
    };

    // Conditionally add module_id if it exists
    if (moduleId) {
      baseState.module_id = moduleId;
    }
    if (!data.priority) {
      delete baseState.priority;
    }
    const CreateCredentials = {
      state: baseState,

      dispatch,
      handleClose,
      handleClear,
      filterDataObj,
    };

    const UpdateCredentials = {
      state: baseState,
      bannerId: isUpdate?.uid,

      dispatch,
      handleClose,
      handleClear,
      filterDataObj,
    };
    if (isUpdate?.uid) {
      dispatch(updateBannerDetails(UpdateCredentials));
    } else {
      dispatch(createBanner(CreateCredentials));
    }
  });

  const BannerTypeOptions = [
    { label: 'Image', value: 'IMAGE' },
    { label: 'Video', value: 'VIDEO' },
  ];
  const PlatformOptions = [
    { label: 'Common', value: 'COMMON' },
    { label: 'Web', value: 'WEB' },
    { label: 'App', value: 'APP' },
  ];
  const POSITION_OPTIONS = [
    { value: 'MAIN', label: 'Main' },
    { value: 'SUB', label: 'Sub' },
  ];

  const LINK_TYPE_OPTIONS = [
    { value: 'INTERNAL', label: 'Internal' },
    { value: 'EXTERNAL', label: 'External' },
  ];

  useEffect(() => {
    if (isUpdate?.image?.public_id) {
      setImagePreview([isUpdate?.image]);
    }
  }, [isUpdate?.image]);
  useEffect(() => {
    if (bannerType === 'VIDEO') {
      setImagePreview([]);
    }
  }, [bannerType]);
  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: 'absolute', top: 0, right: 0, m: 1 }}
        >
          <CloseIcon sx={{ fontSize: '20px' }} />
        </IconButton>
        <DialogTitle id="responsive-dialog-title" sx={{ py: 1.5 }}>
          {isUpdate?.uid ? 'Update Banner' : 'Create Banner'}
        </DialogTitle>

        <DialogContent sx={{ minWidth: isMobile ? 'auto' : '500px' }}>
          <Stack>
            <FormProvider methods={methods} onSubmit={onSubmit}>
              <Grid container maxWidth="xl" spacing={2} mt={0}>
                <Grid xs={12} md={6} item>
                  <RHFSelect name="banner_type" required label="Banner Type" size="small">
                    {BannerTypeOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </RHFSelect>
                </Grid>
                <Grid xs={12} md={6} item>
                  <RHFTextField
                    name="priority"
                    label="Priority"
                    type="number"
                    placeholder="Priority"
                    size="small"
                  />
                </Grid>
                <Grid xs={12} md={6} item>
                  <RHFSelect required name="platform" label="Platform" size="small">
                    {PlatformOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </RHFSelect>
                </Grid>
                <Grid xs={12} md={6} item>
                  <Stack spacing={1} direction="row" alignItems="center">
                    <Typography variant="subtitle2" mr={2}>
                      {' '}
                      Type
                    </Typography>
                    <RHFRadioGroup
                      row
                      spacing={1}
                      name="position"
                      color="success"
                      options={POSITION_OPTIONS}
                    />
                  </Stack>
                </Grid>
                <Grid xs={12} md={12} item>
                  {bannerType !== 'IMAGE' && (
                    <Stack direction={isMobile ? 'column' : 'row'} gap={1} mb={2}>
                      <RHFTextField
                        name="video_id"
                        label="Video ID (Vimeo)"
                        type="string"
                        placeholder="eg: 38395525"
                        size="small"
                        sx={{ width: '100%' }}
                        required={bannerType === 'VIDEO'}
                      />
                      <Button
                        sx={{ width: '180px', mx: 1, fontSize: isMobile && 'x-small' }}
                        variant="contained"
                        color="inherit"
                        disabled={!watch('video_id')}
                        onClick={() =>
                          setVideoPreviewPopup({
                            status: true,
                            data: watch('video_id'),
                          })
                        }
                      >
                        Show Preview
                      </Button>
                    </Stack>
                  )}
                </Grid>
              </Grid>
              <Stack
                spacing={0}
                sx={{ p: 3, px: 0, pt: 0, mb: bannerType === 'IMAGE' ? 0 : 8 }}
                direction="column"
                gap={1}
              >
                <Stack spacing={1} direction="row" alignItems="center">
                  <Typography variant="subtitle2" mr={4} my={2}>
                    {' '}
                    Link Type
                  </Typography>
                  <RHFRadioGroup
                    row
                    spacing={1}
                    name="link_type"
                    color="success"
                    options={LINK_TYPE_OPTIONS}
                  />
                </Stack>
                <RHFTextField
                  name="link"
                  label="Link"
                  type="string"
                  size="small"
                  placeholder="Link"
                />
              </Stack>

              <Stack sx={{}}>
                <Stack
                  sx={{
                    mb: isMobile ? 3 : 1,
                    position: 'absolute',
                    backgroundColor: theme?.palette?.mode === 'dark' ? '' : 'white',
                    bottom: isMobile ? '-166px' : '10px',
                    right: bannerType !== 'IMAGE' ? 20 : 40,
                    width: '100%',
                    zIndex: 99,
                    pb: isMobile ? 20 : '-138px',
                  }}
                  flexDirection="row"
                  alignItems="center"
                >
                  <Button
                    variant="outlined"
                    color="inherit"
                    size="small"
                    sx={{ marginLeft: 'auto' }}
                    onClick={() => handleClose()}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    size="small"
                    loading={isSubmitting || loading}
                    sx={{
                      color: 'white',
                      width: 'fit-content',
                      marginLeft: '10px',
                      backgroundColor: '#106E58',
                      '&:hover': {
                        backgroundColor: '#0b4e3e',
                        boxShadow: 'unset',
                      },
                    }}
                    color="warning"
                    disabled={bannerType === 'IMAGE' && imagePreview.length === 0}
                  >
                    {isUpdate?.uid ? 'Update ' : 'Create '}
                  </LoadingButton>
                </Stack>
              </Stack>
            </FormProvider>
            {bannerType === 'IMAGE' && (
              <Stack sx={{ mb: 8 }}>
                <Typography variant="subtitle2" sx={{ mb: 0, pb: 2 }}>
                  <Typography variant="subtitle2" display="flex" direction="row">
                    Image <Typography color="error"> *</Typography>
                  </Typography>

                  <Divider sx={{ mt: 1 }} />
                </Typography>
                <Stack>
                  <Stack direction="row" gap={2}>
                    <OspImageUploader
                      autoWidth
                      single
                      setImagesPreview={setImagePreview}
                      imagesPreview={imagePreview}
                      folder="banners"
                    />
                  </Stack>
                  {/* <Divider sx={{ mt: 2 }} /> */}
                </Stack>
              </Stack>
            )}
          </Stack>
        </DialogContent>
      </Dialog>

      <VideoPreview
        setOpen={setVideoPreviewPopup}
        open={videoPreviewPopup.status}
        data={videoPreviewPopup.data}
      />
    </div>
  );
}
