import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import AddRoundedIcon from '@mui/icons-material/AddRounded';
// @mui
import {
  Tab,
  Card,
  Grid,
  Tabs,
  Chip,
  Stack,
  Paper,
  Table,
  Button,
  useTheme,
  TableBody,
  TableCell,
  TextField,
  TableContainer,
  InputAdornment,
  CircularProgress,
} from '@mui/material';

import { paths } from 'src/routes/routes/paths';

// components
import Scrollbar from 'src/components/scrollbar';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';

// import BankCrud from 'src/sections/Bank/BankCrud';
// import BankSort from 'src/sections/Bank/BankSort';
// import BankSearch from 'src/sections/Bank/BankSearch';
import { debounce } from 'lodash';

import { useRouter } from 'src/routes/hooks';

import { clearBlogState } from 'src/server/store/blogs';
import { clearReviewState } from 'src/server/store/reviews';
import { getAllBlogs, getBlogCounts, updateBlogStatus } from 'src/server/api/blogs';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import { LoadingScreen } from 'src/components/loading-screen';

import BlogsTableRow from './sections/Table/blogTableRow';
// sections

export default function BlogList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const router = useRouter();
  const table = useTable({
    defaultOrderBy: 'createdAt',
    defaultRowsPerPage: 20,
    defaultDense: true,
  });

  const allBlogs = useSelector((state) => state.blog.blogs);
  const loading = useSelector((state) => state.blog.loading);
  const blogCount = useSelector((state) => state.blog.blogCounts);
  const statusLoading = useSelector((state) => state.blog.statusLoading);

  // const [searchText, setSearchText] = useState();
  // const [finalSearchText, setFinalSearchText] = useState('');
  const [tableData, setTableData] = useState([]);
  const canReset = false;
  const notFound = (!tableData?.length && canReset) || !tableData?.length;
  const identifier = 'review';
  const storedRowsPerPage = localStorage.getItem(`rowsPerPage_${identifier}`);
  const total = allBlogs?.total;
  const [filterData, setFilterData] = useState({
    is_deleted: false,
  });
  const [searchText, setSearchText] = useState();
  const [finalSearchText, setFinalSearchText] = useState('');
  const [tab, setTab] = useState('all');

  // const [productSearchText, setProductSearchText] = useState();
  // console.log(productSearchText);
  // const [finalProductSearchText, setFinalProductSearchText] = useState('');

  // const [userSearchText, setUserSearchText] = useState();
  // console.log(userSearchText);
  // const [finalUserSearchText, setFinalUserSearchText] = useState('');

  const TABLE_HEAD = [
    { id: 'date', label: 'Date' },
    { id: 'title', label: 'Title' },
    { id: 'status', label: 'Status.' },
    { id: 'actions', label: 'Actions' },
  ];

  useEffect(() => {
    setTableData(allBlogs?.blogs);
  }, [allBlogs?.blogs]);

  const updateURLParams = (key, value, extraState = {}) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('p', 1);
    searchParams.set('l', table.rowsPerPage);
    navigate(
      {
        pathname: location.pathname,
        search: searchParams.toString(),
      },
      {
        state: {
          ...location.state,
          page: 0,
          ...extraState,
          [key]: value,
        },
      }
    );
    // history.push({
    //   pathname: location.pathname,
    //   search: searchParams.toString(),
    //   state: {
    //     ...location.state,
    //     page: 1,
    //     ...extraState,
    //     [key]: value,
    //   },
    // })
  };

  useEffect(() => {
    setTab(location?.state?.sort || 'all');
    setSearchText(location?.state?.search || '');
    setFinalSearchText(location?.state?.search || '');
  }, [location?.state?.sort, location?.state?.search, filterData]);

  useEffect(() => {
    if (tab) {
      switch (tab) {
        case 'all':
          setFilterData({
            is_deleted: false,
          });
          break;

        case 'published':
          setFilterData({
            is_deleted: false,
            is_active: true,
          });
          break;

        case 'draft':
          setFilterData({
            is_deleted: false,
            is_active: false,
          });
          break;
        case 'featured':
          setFilterData({
            is_deleted: false,
            is_featured: true,
          });
          break;
        default:
          setFilterData({
            is_deleted: true,
          });
          break;
      }
    }
  }, [tab]);

  useEffect(() => {
    const filterDataObj = {
      filter_data: filterData,
      sort_data: { priority: -1 },
    };
    const credentials = {
      page: table.page + 1,
      search: finalSearchText,
      dispatch,
      limit: table.rowsPerPage || storedRowsPerPage,
      state: filterDataObj,
    };
    dispatch(getAllBlogs(credentials));
  }, [dispatch, table.page, table.rowsPerPage, storedRowsPerPage, filterData, finalSearchText]);

  const handleViewRow = (id) => {
    navigate(paths.dashboard.rental_view(id));
  };

  const debouncedSetDebouncedText = debounce((value) => setFinalSearchText(value), 600);

  const handleChangeSearchText = (e) => {
    debouncedSetDebouncedText(e.target.value);
    setSearchText(e.target.value);
    updateURLParams('search', e.target.value);
  };

  const TABS = [
    {
      value: 'all',
      label: 'All',
      color: 'default',
      count: blogCount?.all_blogs_count,
    },
    {
      value: 'published',
      label: 'Published',
      color: 'success',
      count: blogCount?.all_published_blogs_count,
    },
    {
      value: 'draft',
      label: 'Draft',
      color: 'info',
      count: blogCount?.all_drafted_blogs_count,
    },
    {
      value: 'featured',
      label: 'Featured',
      color: 'warning',
      count: blogCount?.all_featured_blogs_count,
    },
    {
      value: 'is_deleted',
      label: 'Archived',
      color: 'primary',
      count: blogCount?.all_deleted_count,
    },
  ];

  const handleFilterStatus = (e, value) => {
    setTab(value);
    switch (value) {
      case 'all':
        setFilterData({
          is_deleted: false,
        });
        break;

      case 'published':
        setFilterData({
          is_deleted: false,
          is_active: true,
        });
        break;

      case 'draft':
        setFilterData({
          is_deleted: false,
          is_active: false,
        });
        break;
      case 'featured':
        setFilterData({
          is_deleted: false,
          is_featured: true,
        });
        break;
      default:
        setFilterData({
          is_deleted: true,
        });
        break;
    }

    updateURLParams('sort', value);
  };

  const handleReviewCreate = () => {
    dispatch(clearBlogState());
    navigate(paths.dashboard.settings.blog_create);
  };

  const handleCallCountApi = useCallback(() => {
    const filterDataObj = {
      count_list: [
        {
          title: 'all_blogs_count',
          filter_data: {
            is_deleted: false,
          },
        },
        {
          title: 'all_published_blogs_count',
          filter_data: {
            is_deleted: false,
            is_active: true,
          },
        },
        {
          title: 'all_drafted_blogs_count',
          filter_data: {
            is_deleted: false,
            is_active: false,
          },
        },
        {
          title: 'all_featured_blogs_count',
          filter_data: {
            is_deleted: false,
            is_featured: true,
          },
        },
        {
          title: 'all_deleted_count',
          filter_data: {
            is_deleted: true,
          },
        },
      ],
    };

    const credentials = {
      dispatch,
      state: filterDataObj,
    };
    dispatch(getBlogCounts(credentials));
  }, [dispatch]);

  useEffect(() => {
    handleCallCountApi();
  }, [handleCallCountApi, allBlogs]);

  const handleChangeStatus = (uid, value) => {
    const credentials = {
      blogId: uid,
      state: { is_active: value },
      dispatch,
    };
    dispatch(updateBlogStatus(credentials));
  };

  const handleEditRow = (row) => {
    dispatch(clearReviewState());
    router.push({
      pathname: paths.dashboard.settings.blog_update(row?.uid),
      query: {},
    });
  };

  return (
    <>
      <CustomBreadcrumbs
        isMinimal
        action={
          <Stack
            spacing={2}
            px={3}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="start"
              className="product-tap-container"
            >
              <Tabs
                value={tab}
                onChange={handleFilterStatus}
                sx={{
                  px: 2,
                  backgroundColor: theme?.palette?.mode === 'dark' ? '' : '#F4F6F8',
                  borderRadius: '6px',
                }}
              >
                {TABS.map((tabItem) => (
                  <Tab
                    key={tabItem.value}
                    value={tabItem.value}
                    label={tabItem.label}
                    iconPosition="end"
                    icon={
                      <>
                        <Label
                          variant={(tabItem.value === tab && 'filled') || 'soft'}
                          color={tabItem.color}
                          sx={{ ml: 1 }}
                        >
                          {tabItem?.count || 0}
                        </Label>

                        {tabItem.value === tab && (
                          <Chip
                            sx={{
                              backgroundColor: theme?.palette?.mode === 'dark' ? 'grey' : '#fff',
                              position: 'absolute',
                              zIndex: -1,
                              width: '100%',
                              top: '8px',
                              '&:hover': {
                                backgroundColor:
                                  theme?.palette?.mode === 'dark' ? '#232324' : '#e1eaf1',
                              },
                            }}
                          />
                        )}
                      </>
                    }
                    sx={{
                      position: 'relative',
                      zIndex: 1,
                      overflow: 'inherit',
                      width: 'auto',
                      padding: '0 15px',
                    }}
                  />
                ))}
              </Tabs>
              <Grid className="blog-custom-search-input">
                <TextField
                  value={searchText}
                  onChange={handleChangeSearchText}
                  placeholder="Search Blog..."
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                      </InputAdornment>
                    ),
                  }}
                  sx={{ mx: 1, width: 400 }}
                />
              </Grid>
            </Stack>
            <Stack>
              <Grid sx={{ textAlign: 'right' }}>
                <Button
                  variant="contained"
                  size="small"
                  color="success"
                  sx={{ mx: 1, ml: 'auto', px: 3 }} // Move button to the right
                  onClick={() => handleReviewCreate()}
                >
                  <AddRoundedIcon fontSize="small" />
                  Blog
                </Button>
              </Grid>
            </Stack>
          </Stack>
        }
        sx={{
          mb: { xs: 3, md: 2 },
        }}
      />
      <Scrollbar>
        {statusLoading && (
          <Stack sx={{ mb: 2, width: '100%', alignItems: 'center', justifyContent: 'center' }}>
            <LoadingScreen sx={{ width: '100%' }} maxWidth="100%" />
          </Stack>
        )}
        <Card sx={{ mx: 3, mb: 3, boxShadow: 'none', border: '0.1px solid #f9f9f9' }}>
          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={table.dense}
              numSelected={table.selected?.length}
              rowCount={tableData?.length}
              onSelectAllRows={(checked) => {
                table.onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                );
              }}
            />
            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData?.length}
                  numSelected={table.selected?.length}
                  onSort={table.onSort}
                />

                <TableBody>
                  {loading ? (
                    <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <CircularProgress color="inherit" />
                      </Paper>
                    </TableCell>
                  ) : (
                    <>
                      {(tableData || []).map((row, index) => (
                        <BlogsTableRow
                          index={index}
                          page={table.page + 1}
                          rowsPerPage={table.rowsPerPage || 5}
                          key={row.id}
                          row={row}
                          selected={table?.selected?.includes(row.id)}
                          onSelectRow={() => table.onSelectRow(row.id)}
                          onViewRow={() => handleViewRow(row.uid)}
                          handleChangeStatus={handleChangeStatus}
                          dispatch={dispatch}
                          tab={tab}
                          onEditRow={() => handleEditRow(row)}
                          // onDeleteRow={() => handleDeleteClick(row)}
                          loading={loading}
                          colSpan={TABLE_HEAD?.length}
                        />
                      ))}
                    </>
                  )}
                </TableBody>

                {/* <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(table.page, table.rowsPerPage, tableData?.length)}
                />  */}

                <TableNoData notFound={notFound} />
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={total || 0}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            dense={table.dense}
            onChangeDense={table.onChangeDense}
            identifier={identifier}
          />
        </Card>
      </Scrollbar>
    </>
  );
}
