import axios from 'axios';

import { paths } from 'src/routes/routes/paths';

// eslint-disable-next-line import/no-cycle
import { useGetRoles, useGetToken } from 'src/hooks/useHandleSessions';

const env = 'prod';
export const TOKEN_PREFIX = 'token';

// dev
const DEV_OPERATION_BASE_URL = 'https://operations.buildex.osperb.com';
const DEV_RESOURCE_BASE_URL = 'https://res.buildex.osperb.com';

// prod
const PROD_OPERATION_BASE_URL = 'https://operation.buildexstores.com';
const PROD_RESOURCE_BASE_URL = 'https://res.buildexstores.com';

// development URLs
// const DEV_OPERATION_BASE_URL = 'https://operations.buildex.osperb.com';
// const DEV_RESOURCE_BASE_URL = 'https://res.buildexstores.com';

export const OPERATION_BASE_URL = env === 'dev' ? DEV_OPERATION_BASE_URL : PROD_OPERATION_BASE_URL;
export const RESOURCE_BASE_URL = env === 'dev' ? DEV_RESOURCE_BASE_URL : PROD_RESOURCE_BASE_URL;

export function createAxiosInstance(type = 'operation') {
  const newBase = type === 'resource' ? RESOURCE_BASE_URL : OPERATION_BASE_URL;
  const axiosInstance = axios.create({
    baseURL: newBase,
  });

  axiosInstance.interceptors.request.use(
    (config) => {
      const { token } = useGetToken(TOKEN_PREFIX);
      const { role } = useGetRoles();

      if (token) {
        config.headers.authorization = `Bearer ${token}`;
      }

      const handleDashboardApp = () => {
        switch (role) {
          case 'admin':
            return 'admin';
          default:
            return 'admin';
        }
      };

      if (config && config.url && config.url.includes('/admin')) {
        const parts = config.url.split('/admin');
        config.url = `${parts[0]}/${handleDashboardApp()}${parts[1]}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  // Response interceptor to handle custom response status codes
  axiosInstance.interceptors.response.use(
    (response) => {
      switch (response?.data?.status) {
        case 2000:
          return {
            data: { ...response?.data?.data, isSuccess: true },
          };

        case 2001:
        case 1001: // Unauthorized
          return {
            data: response?.data,
          };

        default:
          console.error('Error: ', response?.data?.message || 'Unknown error');
          return response?.data?.message
            ? Promise.reject(new Error(response?.data?.message || 'Unknown error'))
            : {};
      }
    },
    (err) => {
      if (err.response) {
        console.error('Error status:', err.response.status);
        console.error('Error data:', err.response.data);
      }
      return Promise.reject(err);
    }
  );
  // Response interceptor to handle expire/undefined token

  axios.interceptors.response.use(
    (response) => response,
    (err) => {
      console.error(err.response.status);
      if (err.response.status === 4001) {
        sessionStorage.clear('token');
        window.location.replace(paths.public.landing);
        return Promise.reject(err);
      }
      return Promise.reject(err);
    }
  );
  return axiosInstance;
}
